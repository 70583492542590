<div class="menu-wrapper fixed-top d-flex justify-content-between">
  <div class="logo d-flex">
    <img src="../../../assets/GM-logo.png" />
    <div class="page-title" [ngClass]="{'lengthy-text': pageHeaderTitle?.length > 50}">{{pageHeaderTitle}}</div>
  </div>

  <div class="menu d-none d-md-flex">
    <ng-container *ngTemplateOutlet="mainMenu"></ng-container>
  </div>

  @if (userDetails) {
    <button class="mobile-menu-button d-block d-md-none align-self-center" mat-mini-fab (click)="switchMenu()">
      <mat-icon>{{toggleMobileMenu ? 'menu_open' : 'menu'}}</mat-icon>
    </button>
  } @else if(isQuestionaryFormPage) {
    <button class="mobile-menu-button align-self-center ml-auto" mat-mini-fab [routerLink]="'/'">
      <mat-icon>arrow_back</mat-icon>
    </button>
  }
</div>

<div class="mobile-menu-container d-md-none d-flex" *ngIf="toggleMobileMenu">
  <ng-container *ngTemplateOutlet="mainMenu"></ng-container>
</div>


<div class="content-wrapper">
  <router-outlet></router-outlet>
</div>
<footer class="fixed-bottom bg-drak-blue fixed-bottom d-print-none">
  <div class="foot d-flex justify-content-between px-3 pt-2 w-100">
    <div class="sec-1 d-flex ">
      <a href="https://www.gm.com/privacy-statement" target="_blank">GM Privacy Policy |</a>
      <a href="https://www.acbcoop.com/privacy-policy/" target="_blank">Privacy Policy |</a>
      <a href="https://www.acbcoop.com/cookie-policy/" target="_blank">Cookie Policy </a>
    </div>

    <div class="sec-3 d-flex">
      <p><i class="material-icons" id="btncopyright">copyright</i><span class="year">{{todayDate | date :
          'YYYY'}}</span> General Motors LLC All Rights Reserved</p>
    </div>
  </div>
</footer>

<!-- main menu -->
 <ng-template #mainMenu>
  <div class="menu-item d-flex" *ngIf="menuList.length > 0">
    @for(menu of menuList; track menu) {
      @if(menu.id !== 'reports') {
        <a [routerLink]="getNavLink(pageRouter[menu.id])" (click)="switchMenu(true)">{{menu.label}}</a>
      } @else {
        <a [matMenuTriggerFor]="reports" class="report-menu d-flex align-items-center">Reports <mat-icon class="report-menu" iconPositionEnd>expand_more</mat-icon></a>
      }
    }
    <a>
      <div>
        <div class="user-name-wrapper d-flex" [matMenuTriggerFor]="menu">
          <mat-icon class="user-icon">account_circle</mat-icon>
          <span class="user-name-text">{{userDetails}}</span>
          <mat-icon class="user-dropdown-icon" iconPositionEnd>expand_more</mat-icon>
        </div>
  
        <mat-menu #menu="matMenu" xPosition="before">
          <button mat-menu-item class="logout-btn" (click)="logoutUser()">Logout</button>
        </mat-menu>
        
      </div>
    </a>
  </div>
 </ng-template>


<!-- reports sub menu -->
<mat-menu #reports="matMenu" xPosition="before" class="main-sub-menus">
  @for(subMenu of getSubMenus(menuList, 'reports'); track subMenu) {
    <a [routerLink]="getNavLink(pageRouter[subMenu.id])" (click)="switchMenu(true)">{{subMenu.label}}</a>
  }
 </mat-menu>