import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { AuthenticationService } from '../../modules/authentication/services/authentication.service';
import { PAGE_ROUTER, getNavLink } from '../../constants/page-router.constants';
import {MatMenuModule} from '@angular/material/menu';
import { filter } from 'rxjs';

@Component({
  selector: 'app-menu-layout',
  standalone: true,
  imports: [RouterModule,
    CommonModule,
    MatIconModule,
    MatMenuModule
    

  ],
  templateUrl: './menu-layout.component.html',
  styleUrl: './menu-layout.component.scss'
})
export class MenuLayoutComponent implements OnInit {
  todayDate: Date = new Date();
  menuList: any[] = [];
  name: any;
  pageRouter : any = PAGE_ROUTER;
  userDetails:any = '';
  pageHeaderTitle: any ='Dealer Billing Portal';
  getNavLink = getNavLink;
  userMenuList: any = [
    {
      id: '',
      title: 'Change Password',
      icon: 'lock',
      url: '/auth/change-password',
    },
  ];

  reportSubMenus = [];
  toggleMobileMenu: boolean = false;
  isQuestionaryFormPage: boolean = false;
  constructor(private authenticationService: AuthenticationService,
    private router: Router, private route: ActivatedRoute
  ) {

  }
  
  ngOnInit(): void {
    this.menuList = this.authenticationService.getMenuList();
    if(this.authenticationService.getToken()){
      this.userDetails = this.authenticationService.getUserObj().firstName + " " + this.authenticationService.getUserObj().lastName;
    }

    this.updatePageHeaderTitle(this.router.url);
    this.isQuestionaryFormPage = this.router.url.includes('vendor-questionnaire/submission-form');
    this.router.events.subscribe(() => {
      this.updatePageHeaderTitle(this.router.url);
      this.isQuestionaryFormPage = this.router.url.includes('vendor-questionnaire/submission-form');
    });
     }
   
     updatePageHeaderTitle(url: string) {
      if (url.includes('submission-form') ) {
        this.pageHeaderTitle = 'GM Dealer Digital Solution Program Vendor Questionnaire';
      } else if (url.includes('submit-confirmation') || url.includes('questionnaire-history')){
        this.pageHeaderTitle = 'GM Vendor Questionnaire';
      } else {
        this.pageHeaderTitle = 'Dealer Billing Portal';
      }
    }

  
  logoutUser() {
    this.authenticationService.logOutUser();
    this.userDetails = '';
  }

  getSubMenus(mainMenu: any[], subMenuId: string) {
    const matchMenu =  mainMenu && mainMenu.find( menu => menu.id === subMenuId);
    return matchMenu ? matchMenu.menu : [];
  }

  switchMenu(closeMenu: boolean = false): void {
    this.toggleMobileMenu = closeMenu ? false : !this.toggleMobileMenu;
  }
}